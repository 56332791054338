<template>
  <ConnectorContentBlock>

    <template #title>
      {{ $t('Web.Spreadsheet.TitleGeneral') }}
    </template>
    <div
      v-if="$sl_isEditingRoute"
      class="connector-content-row connector-content-row--column connector-content-block__row--divider"
    >
      <SlInfoItem
        v-if="isTransactional"
        :label="$t('DbInsideImport.Ui.lbGroupBy')"
        :value="groupByLabel"
      />
      <SlInfoItem
        v-if="isTransactional"
        :label="startFromKeyText"
        :value="startFromValueText"
      />
      <SlInfoItem
        :label="$t('OrderListDialog.Ui.lbComposeDate')"
        :value="composeDateLabel"
      />
      <SlInfoItem
        v-if="isTransactional"
        :label="$t('OrderListDialog.Ui.chkStickLocations')"
        :value="uniteLocationsValueText"
      />
    </div>
    <div
      v-if="isCsv || isTransactional || !$sl_isEditingRoute "
      class="connector-content-row connector-content-row--column connector-content-block__row--divider"
    >
      <template v-if="$sl_isEditingRoute">
        <div
          v-if="isCsv"
          class="connector-content-row"
        >
          <SlSelect
            v-model="csvDelimiterModel"
            :select-label="$t('OrderListDialog.Ui.lbCsvDelimiter')"
            :options="csvDelimiterOptions"
            inline
          />
        </div>
        <div
          v-if="isTransactional"
          class="connector-content-row"
        >
          <SlValidate
            v-slot="{ invalid }"
            key="headerRowsCount"
            vid="headerRowsCount"
            rules="required|between:0,99"
          >
            <SlControlInput
              v-model="headerRowsCountModel"
              :min="0"
              :max="99"
              :label="$t('OrderListDialog.Ui.lbHeaderRows')"
              :is-invalid="invalid"
            />
          </SlValidate>
        </div>
      </template>
      <div
        v-else
        class="connector-content-row connector-content-row--column"
      >
        <div
          v-if="isCsv"
          class="connector-content-row"
        >
          <SlSelect
            v-model="csvDelimiterModel"
            :select-label="$t('OrderListDialog.Ui.lbCsvDelimiter')"
            :options="csvDelimiterOptions"
            inline
          />
        </div>
        <div
          v-if="isTransactional"
          class="connector-content-row"
        >
          <SlControl
            v-model="groupByModel"
            :options="groupByOptions"
            :label="$t('CreateNewProject.Ui.lbGroupBy')"
            :description="$t('Web.DbImport.LabelDescrGroupBy')"
          >
            <template #icon>
              <SlInfoButton
                v-tooltip="getTooltip({
                  content: $t('Web.DbImport.TooltipGroupBy')
                })"
              />
            </template>
          </SlControl>
          <SlValidate
            v-if="groupByModel === groupBy.MONTH"
            v-slot="{ invalid }"
            key="startFromDay"
            vid="startFromDay"
            :rules="{
              required: true,
              between: {
                min: 1,
                max: 31,
                message: $t('Web.Validation.InvalidValue')
              }
            }"
          >
            <SlControlInput
              v-model="startFromModel"
              :min="1"
              :max="31"
              :label="$t('Web.DbImport.LabelStartFromDay')"
              :description="$t('Web.DbImport.LabelDescrStartFrom')"
              :is-invalid="invalid"
            />
          </SlValidate>
          <SlSelect
            v-if="groupByNoMonth"
            v-model="startFromModel"
            :select-label="$t('Web.DbImport.LabelStartFrom')"
            :options="startFromOptions"
            :disabled="groupByModel === groupBy.DAY"
            inline
          />
        </div>
        <div class="connector-content-row">
          <SlSelect
            v-model="composeDateModel"
            :select-label="$t('OrderListDialog.Ui.lbComposeDate')"
            :options="composeDateOptions"
            class="w-240"
          />
        </div>
        <div
          v-if="isTransactional"
          class="connector-content-row"
        >
          <SlValidate
            v-slot="{ invalid }"
            key="headerRowsCount"
            vid="headerRowsCount"
            rules="required|between:0,99"
          >
            <SlControlInput
              v-model="headerRowsCountModel"
              :min="0"
              :max="99"
              :label="$t('OrderListDialog.Ui.lbHeaderRows')"
              :is-invalid="invalid"
            />
          </SlValidate>
        </div>
        <div
          v-if="isTransactional"
          class="connector-content-row"
        >
          <SlCheckbox
            v-model="uniteLocationsModel"
            :label="$t('OrderListDialog.Ui.chkStickLocations')"
          />
        </div>
      </div>
      <div
        v-if="isTransactional"
        class="connector-content-row"
      >
        <SlCheckbox
          v-model="zeroPriceTransactionsModel"
          :label="$t('OrderListDialog.Ui.chkZeroPriceTransactions')"
        />
      </div>
      <div
        v-if="isTransactional"
        class="connector-content-row"
      >
        <SlCheckbox
          v-model="zeroQtyTransactionsModel"
          :label="$t('DbImportDialog.Ui.chkZeroQtyTransactions')"
        />
      </div>
    </div>
    <div class="connector-content-additional">
      <div class="connector-content-additional__title">
        {{ $t('Web.Spreadsheet.TitleUploadedFiles') }}
      </div>
      <div class="connector-content-additional__subtitle">
        {{ $t('Web.Spreadsheet.SubtitleUploadedFile') }}
      </div>
      <div class="connector-content-additional__subtitle">
        {{ $t('Web.Spreadsheet.DescriptionMaxFiles', { 1: MAX_FILES }) }}
      </div>
      <div class="connector-content-additional__content">
        <transition name="fade-down">
          <div
            v-if="filesLength"
            class="connector-content-row"
          >
            <SlSwiperContainer
              id="spreadsheet-preview"
              :slides-count="filesLength"
              slides-per-view="auto"
              class="sl-swiper"
            >
              <SlSwiperSlide
                v-for="file in files"
                :key="file.id"
              >
                <SlDropzonePreviewItem
                  :file="file"
                  :reupload="false"
                  @remove="handleRemoveFile(file.fileId)"
                />
              </SlSwiperSlide>
            </SlSwiperContainer>
          </div>
        </transition>
        <div
          v-tooltip="getTooltip({
            content: $t('Web.FileUpload.TooltipMaxFiles', { 1: MAX_FILES }),
            disabled: files.length < MAX_FILES
          })"
          class="connector-content-row"
        >
          <SlButton
            variant="secondary"
            color="grey"
            :disabled="files.length >= MAX_FILES"
            @click="showModal(modalsId.SPREADSHEET_UPLOAD, {
              callback: handleReupload
            })"
          >
            <template #prepend>
              <icon
                data="@icons/upload_cloud.svg"
                class="fill-off size-16"
              />
            </template>
            {{ $t('Web.File.UploadFile') }}
          </SlButton>
        </div>
      </div>
    </div>
  </ConnectorContentBlock>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ConnectorContentBlock from '@/components/Connections/Common/ConnectorContentBlock';
import { modal } from '@/mixins/modal';
import { groupByOptions, groupBy, connectionTypes } from '@/config/connection';
import modalsId from '@/config/shared/modalsId.config';
import { composeDateOptions, csvDelimiterOptions, MAX_FILES, tabKeys } from '@/config/connection/spreadsheet.config';
import { weekDays } from '@/config/utils/dateInfo.config';
import { getLabelFromBoolean } from '@/helpers/locale/getLabel';

export default {
  name: 'General',
  components: {
    ConnectorContentBlock
  },
  mixins: [modal],
  inheritAttrs: false,
  inject: [
    'getTooltip',
    'setSettings'
  ],
  data() {
    return {
      MAX_FILES,
      groupBy,
      modalsId,
      csvDelimiterOptions
    };
  },
  computed: {
    ...mapState({
      commonData: state => state.spreadsheet.commonData,
      files: state => state.spreadsheet.files,
      tabData: state => state.spreadsheet[tabKeys.GENERAL],
      matchedSheets: state => state.spreadsheet[tabKeys.MATCH_SHEETS]
    }),
    ...mapGetters({
      isCsv: 'spreadsheet/isCsv',
      isMultipleFiles: 'spreadsheet/isMultipleFiles',
      isFileUsed: 'spreadsheet/isFileUsed'
    }),
    groupByOptions() {
      return groupByOptions(this);
    },
    startFromOptions() {
      return weekDays(this);
    },
    composeDateOptions() {
      return composeDateOptions(this);
    },
    groupByNoMonth() {
      return [groupBy.WEEK, groupBy.DAY].includes(this.groupByModel);
    },
    isTransactional() {
      return this.commonData.type === connectionTypes.SPREADSHEET_TRANSACTIONAL;
    },
    groupByModel: {
      get() {
        return this.tabData.groupBy;
      },
      set(value) {
        this.setSettings({ value, key: 'groupBy' });
        this.startFromModel = [groupBy.WEEK, groupBy.DAY].includes(value) ? 0 : 1;
      }
    },
    csvDelimiterModel: {
      get() {
        return this.tabData.csvDelimiter;
      },
      set(value) {
        this.setSettings({ value, key: 'csvDelimiter' });
      }
    },
    startFromModel: {
      get() {
        return this.tabData.startFrom;
      },
      set(value) {
        this.setSettings({ value, key: 'startFrom' });
      }
    },
    composeDateModel: {
      get() {
        return this.tabData.composeDate;
      },
      set(value) {
        this.setSettings({ value, key: 'composeDate' });
      }
    },
    headerRowsCountModel: {
      get() {
        return this.tabData.headerRowsCount;
      },
      set(value) {
        this.setSettings({ value, key: 'headerRowsCount' });
      }
    },
    uniteLocationsModel: {
      get() {
        return this.tabData.uniteLocations;
      },
      set(value) {
        this.setSettings({ value, key: 'uniteLocations' });
      }
    },
    zeroPriceTransactionsModel: {
      get() {
        return this.tabData.zeroPriceTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroPriceTransactions' });
      }
    },
    zeroQtyTransactionsModel: {
      get() {
        return this.tabData.zeroQtyTransactions;
      },
      set(value) {
        this.setSettings({ value, key: 'zeroQtyTransactions' });
      }
    },
    groupByLabel() {
      return this.groupByOptions.find(option => option.value === this.groupByModel)?.label
        || this.groupByOptions[0].label;
    },
    startFromKeyText() {
      return this.groupByNoMonth
        ? this.$t('Web.DbImport.LabelStartFrom')
        : this.$t('Web.DbImport.LabelStartFromDay');
    },
    startFromValueText() {
      return this.groupByNoMonth
        ? this.startFromOptions.find(item => +item.value === +this.startFromModel)?.label
        : this.startFromModel;
    },
    composeDateLabel() {
      return this.composeDateOptions.find(option => option.value === this.composeDateModel).label;
    },
    uniteLocationsValueText() {
      return getLabelFromBoolean(this.uniteLocationsModel, this);
    },
    filesLength() {
      return this.files.length;
    }
  },
  methods: {
    ...mapActions({
      removeFile: 'spreadsheet/removeFile',
      matchDefaultSheet: 'spreadsheet/matchDefaultSheet',
      fetchBasicSettings: 'spreadsheet/fetchBasicSettings'
    }),
    async handleReupload() {
      await this.fetchBasicSettings(true);
      this.matchDefaultSheet();
    },
    handleRemoveFile(fileId) {
      if (this.isFileUsed(fileId)) {
        return this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Modals.ActionConfirm.TitleDeleteFile'),
          text: this.$t('Web.Modals.ActionConfirm.TextDeleteFile'),
          icon: 'style_warning_double',
          confirmText: this.$t('Web.Modals.ActionConfirm.ButtonDelete'),
          confirmCallback: () => this.removeFile(fileId)
        });
      }

      this.removeFile(fileId);
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-content-block::v-deep {
  .connector-content-block__main {
    row-gap: 0;

    height: auto !important;
    max-height: initial !important;
    padding-bottom: 24px;
  }
}

.connector-content-additional__content {
  margin-top: 12px;

  .connector-content-row {
    &:first-child {
      display: block;
    }

    &:last-child {
      width: fit-content;
    }
  }
}
</style>
